import React, { useEffect } from 'react'
import Flickity from 'react-flickity-component'
import { useMintCallback } from 'hooks/useMintCallback'
import Swal from 'sweetalert2'

export default function Home() {

    const [transactionStatus, mintQuantumon] = useMintCallback()

    useEffect(() => {
        if (transactionStatus.status === "completed") Swal.fire({
            icon: "success",
            title: "Minted!",
            html: "<p>You can view you NFT <a href='https://opensea.io/account?tab=private'>here</a></p>",
            footer: "It might take a while for opensea to index your NFT"
        })

        if (transactionStatus.status === "failed") Swal.fire({
            icon: "error",
            title: "Something went wrong",
            html: `<p>${transactionStatus.statusDescription}</p>`
        })
    }, [transactionStatus])


    const mint = async () => {
        await mintQuantumon();
    }

    return (
        <main className="site-main">
            <section className="main-landing">
                <div className="inner-column">

                    <info-card>
                        <div className="mon-types">
                            <p className="mon-type dragon">
                                dragon
                            </p>
                            <p className="mon-type steel">
                                steel
                            </p>
                        </div>
                        <picture>
                            <img src="images/ryuteel.png" alt="" />
                        </picture>

                        <h1 className="card-heading">
                            Ryuteel
                        </h1>

                    </info-card>


                    <div className="text-flex">
                        <h1 className="intrigue-voice">
                            Catch a new monster
                        </h1>
                        <h2 className="attention-voice">
                            World's first Quantum NFT
                        </h2>
                        <p className="calm-voice">
                            Mint a super rare Quantumon and make it a part of your collection!
                        </p>
                        {!['waiting', 'pending', 'minting'].includes(transactionStatus.status) ? <a className="bid-button" href="#" onClick={() => mint()}>
                            <p>Mint a <span>Monster</span></p>
                        </a> : <a className="bid-button" href="#">
                            <p>{transactionStatus.status}...</p>
                        </a>}
                        <p className="solid-voice">
                            Note: minting can sometimes timeout, however the NFT should be visible on your opensea account in a 2-10 mins
                        </p>
                    </div>
                </div>
            </section>

            <section className="carousel-section">
                <div className="inner-column">
                    <Flickity
                        className={'carousel'} // default ''
                        elementType={'div'} // default 'div'
                        options={{
                            initialIndex: 2,
                            prevNextButtons: false,
                            autoPlay: true,
                            wrapAround: true
                        }} // takes flickity options {}
                        reloadOnUpdate // default false
                    >
                        <div className="carousel-cell cell-a"><img src="images/ryuteel.png" alt="" /></div>
                        <div className="carousel-cell cell-b"><img src="images/flying-ghost.png" alt="" /></div>
                        <div className="carousel-cell cell-c"><img src="images/fight-dragon.png" alt="" /></div>
                        <div className="carousel-cell cell-d"><img src="images/bird.png" alt="" /></div>
                        <div className="carousel-cell cell-e"><img src="images/handhog.png" alt="" /></div>
                        <div className="carousel-cell cell-f"><img src="images/normal-bug.png" alt="" /></div>
                        <div className="carousel-cell cell-g"><img src="images/water-fairy.png" alt="" /></div>
                        <div className="carousel-cell cell-h"><img src="images/dark-fighing.png" alt="" /></div>
                        <div className="carousel-cell cell-h"><img src="images/ice-grass.png" alt="" /></div>
                    </Flickity>
                </div>
            </section>




            <section className="about-artist" id="about">
                <div className="inner-column">

                    <div className="arist-image-box">
                        <picture className="arist-image">
                            <img src="images/qrng-logo.svg" alt="" />
                        </picture>

                        <info-card class="arist-image-a">
                            <div className="mon-types">
                                <p className="mon-type dragon">
                                    dragon
                                </p>
                                <p className="mon-type steel">
                                    steel
                                </p>
                            </div>
                            <picture>
                                <img src="images/ryuteel.png" alt="" />
                            </picture>

                            <h1 className="card-heading">
                                Ryuteel
                            </h1>

                        </info-card>

                        <info-card class="arist-image-b">
                            <div className="mon-types">
                                <p className="mon-type air">
                                    air
                                </p>
                                <p className="mon-type air">
                                    air
                                </p>
                            </div>

                            <picture>
                                <img src="images/bird.png" alt="" />
                            </picture>

                            <h1 className="card-heading">
                                Mr. Bird
                            </h1>
                        </info-card>

                        <info-card class="arist-image-c">
                            <div className="mon-types">
                                <p className="mon-type water">
                                    water
                                </p>
                                <p className="mon-type mystic">
                                    mystic
                                </p>
                            </div>

                            <picture>
                                <img src="images/water-fairy.png" alt="" />
                            </picture>

                            <h1 className="card-heading">
                                Aquapuff
                            </h1>
                        </info-card>
                    </div>

                    <div className="text-flex">
                        <h1 className="intrigue-voice">
                            powered by api3 qrng
                        </h1>
                        <h2 className="attention-voice">
                            Mint monsters using Quantum phenomena
                        </h2>
                        <p className="calm-voice">
                            Quantum monsters comes to life in an artistic format when developing generative NFTs – resulting in a collection of dynamic digital assets developed through the organically unpredictable quantum process
                        </p>
                        <a target="_blank" href="#">
                            <picture>
                                <img src="images/new-tab.svg" alt="" />
                            </picture>
                        </a>
                    </div>

                </div>
            </section>

            <section className="about-item">
                <div className="inner-column">

                    <picture>
                        <img src="images/fight-dragon.png" alt="" />
                    </picture>

                    <div className="text-flex">
                        <h1 className="intrigue-voice">
                            About the token
                        </h1>
                        <h2 className="attention-voice">
                            Totally new way to generate NFTs
                        </h2>
                        <p className="calm-voice">
                            All Quantum Monsters are generated via AI to make each one distinct and unique. these are then randomly minted via QRNG
                        </p>
                        <p className="calm-voice">
                            Mint one today to show support for the Launch of QRNG. Quantumon are a token of appreciation to all the members of the API3DAO.
                        </p>
                        <div className="details">
                            <li>
                                <h3>
                                    89
                                </h3>
                                <p>Unique Quantumon</p>
                            </li>
                            <li>
                                <h3>
                                    9958
                                </h3>
                                <p>Total number of monsters</p>
                            </li>
                        </div>
                        <a className="bid-button" href="https://opensea.io/collection/quantumon">
                            Open Sea
                        </a>
                    </div>

                </div>
            </section>
            <section className="collection" id="collect">
                <div className="inner-column">

                    <h1 className="intrigue-voice">
                        COLLECTIONS

                    </h1>
                    <h2 className="attention-voice">
                        Discover more Quantumon
                    </h2>
                    <p className="calm-voice">
                        Check out some more
                    </p>

                    <div className="collection-grid">
                        <div className="left-side">
                            <info-card class="fight-dragon">
                                <div className="mon-types">
                                    <p className="mon-type dark">
                                        dark
                                    </p>
                                    <p className="mon-type dragon">
                                        dragon
                                    </p>
                                </div>
                                <picture>
                                    <img src="images/fight-dragon.png" alt="" />
                                </picture>

                                <h1 className="card-heading">
                                    Goondra
                                </h1>

                            </info-card>

                            <info-card class="normal-bug">
                                <div className="mon-types">
                                    <p className="mon-type earth">
                                        earth
                                    </p>
                                    <p className="mon-type bug">
                                        bug
                                    </p>
                                </div>
                                <picture>
                                    <img src="images/normal-bug.png" alt="" />
                                </picture>

                                <h1 className="card-heading">
                                    garbodil
                                </h1>
                            </info-card>


                        </div>

                        <div className="right-side">
                            <info-card class="ice-grass">
                                <div className="mon-types">
                                    <p className="mon-type air">
                                        air
                                    </p>
                                    <p className="mon-type grass">
                                        grass
                                    </p>
                                </div>
                                <picture>
                                    <img src="images/ice-grass.png" alt="" />
                                </picture>

                                <h1 className="card-heading">
                                    Sprout
                                </h1>

                            </info-card>
                            <div className="right-bottom">
                                <info-card class="dark-fighing">
                                    <div className="mon-types">
                                        <p className="mon-type dark">
                                            dark
                                        </p>
                                        <p className="mon-type mystic">
                                            mystic
                                        </p>
                                    </div>
                                    <picture>
                                        <img src="images/dark-fighing.png" alt="" />
                                    </picture>

                                    <h1 className="card-heading">
                                        Drarara
                                    </h1>

                                </info-card>
                                <info-card class="flying-ghost">
                                    <div className="mon-types">
                                        <p className="mon-type air">
                                            air
                                        </p>
                                        <p className="mon-type ghost">
                                            ghost
                                        </p>
                                    </div>
                                    <picture>
                                        <img src="images/flying-ghost.png" alt="" />
                                    </picture>

                                    <h1 className="card-heading">
                                        Ghosty
                                    </h1>

                                </info-card>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
    )
}
