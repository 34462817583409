import React, { useCallback, useEffect } from "react";
import { useWalletConnectCallback } from "hooks/useWalletConnetCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { setupNetwork } from "utils/helper";
import { NETWORK_TYPE } from "constants";
import { ALL_SUPPORTED_CHAIN_IDS, NETWORK_DETAILS } from "constants/chains";

export default function Header() {
  const [connectWallet] = useWalletConnectCallback();
  const { activate, active, account, chainId, deactivate } = useActiveWeb3React();

  useEffect(() => {
    console.log("connection status ", { active, chainId });
  }, [active, chainId]);

  const handleSwitchNetwork = useCallback(async () => {
    return await setupNetwork(
      NETWORK_TYPE === 1 ? NETWORK_DETAILS.polygon : NETWORK_DETAILS.mainnet
    );
  }, [setupNetwork]);

  const handleWalletConnect = async (connectorType = "injected") => {
    if (active) {
      deactivate()
    }
    else {
      const switchNetwork = await handleSwitchNetwork()
      switchNetwork && connectWallet(connectorType)
    }
  };

  return (
    <header className="site-header">
      <section className="header-nav">
        <h1 className="title-voice">
          Q<span>ua</span>NT<span>u</span>M<span>o</span>N
        </h1>
        <nav>
          <a href="#about">About</a>
          <a href="#collect">Collections</a>
          {!active && (
            <a
              href="#"
              className="bid-button"
              onClick={() => handleWalletConnect()}
            >
              Connect
            </a>
          )}
          {active && <a
            href="#"
            className="bid-button"
            onClick={() => handleWalletConnect()}
          >{account.slice(0, 8)}</a>}
        </nav>
      </section>
    </header>
  );
}
