// import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from "@web3-react/injected-connector";
import { supportedChains } from "../constants";

// Instanciate your other connectors.
export const injected = new InjectedConnector({
  supportedChainIds: supportedChains,
});

const connectors = {
  injected,
};

export default connectors;
