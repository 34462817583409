import { useCallback, useEffect, useMemo, useState } from "react";
import { toWei } from "../utils/helper";
import { ethers } from 'ethers';
import useActiveWeb3React from "./useActiveWeb3React";
import { useAirnodeRrp, useQuantumonContract } from "./useContract";
import useBlockNumber from "./useBlockNumber";

interface TransactionStatus {
  status: string;
  hash: string | null;
  requestId: string | null;
  statusDescription: string | null;
}

export function useMintCallback(): [TransactionStatus, () => {}] {
  const { library, chainId } = useActiveWeb3React();
  const quantumonContract = useQuantumonContract();
  const airnodeRrp = useAirnodeRrp();
  const [data, setData] = useState({ hash: "", status: "", requestId: "", statusDescription: "" });
  const blockNumber = useBlockNumber();

  let mintRes: any = null;

  const setMaxPromiseTimeout = <T>(promise: Promise<T>, timeoutMs: number): Promise<T> =>
    Promise.race([promise, new Promise<never>((_, reject) => setTimeout(() => reject('Timeout exceeded!'), timeoutMs))]);

  const waitForFulfillment = async (requestId: string) => {
    const fulfilled = new Promise((resolve) =>
      library?.once(airnodeRrp!.filters.FulfilledRequest("0x9d3c147ca16db954873a498e0af5852ab39139f2", requestId), (log) => resolve({ log }))
    );
    const failed = new Promise((resolve) =>
      library?.once(airnodeRrp!.filters.FailedRequest("0x9d3c147ca16db954873a498e0af5852ab39139f2", requestId), resolve)
    ).then((rawRequestFailedLog) => {
      const log = airnodeRrp!.interface.parseLog(rawRequestFailedLog as ethers.Event);
      throw new Error(`Request failed. Reason:\n${log.args.errorMessage}`);
    });
    return Promise.race([fulfilled, failed]);
  };

  const mintQuantumon = useCallback(
    async () => {
      try {
        setData({ ...data, status: "waiting", statusDescription: "Please sign in with your metamask wallet" });

        const options = { value: ethers.utils.parseEther("5.0") }
        mintRes = await quantumonContract?.requestQuantumon(options);

        if (mintRes) {
          setData({ ...data, hash: mintRes?.hash, status: "pending", statusDescription: "please wait for the request transaction to be submitted" });
        } else {
          setData({ ...data, status: "failed", statusDescription: "Unable to submit the request transaction.<br/> Make sure your connected with metmask" });
        }
      } catch (error) {
        setData({ ...data, status: "failed", statusDescription: "Error Occured while creating metamask transaction. <p> Do you have sufficient funds in your Wallet?</p><br/> You need 5 MATIC to mint" });
        console.log("mint trx error ", { error });
      }
    },
    [quantumonContract, setData]
  );

  useEffect(() => {
    if (!data?.hash) {
      return;
    }

    if (data?.status === "minting" || data?.status === "failed" || data?.status === "completed") {
      return;
    }

    library
      ?.getTransactionReceipt(data?.hash)
      .then((res) => {
        if (res?.blockHash && res?.blockNumber) {
          setData({ ...data, status: "minting", requestId: airnodeRrp?.interface.parseLog(res.logs[1]).args.requestId });
          console.log("waiting for fullfillment")
          setMaxPromiseTimeout(waitForFulfillment(airnodeRrp?.interface.parseLog(res.logs[1]).args.requestId), 360 * 1000).then((fulfilledRequest) => {
            console.log("Fullfilled", fulfilledRequest)
            setData({ ...data, status: "completed" })
          }).catch((err) => {
            console.log(err);
            setData({ ...data, status: "failed", statusDescription: "Unable to monitor the transaction, don't worry your mint transaction is still ongoing <br/>. You can view it on polygonscan" })
          })
        }
      })
      .catch((err) => {
        console.log("transaction failed ", err);
        setData({ ...data, status: "failed", statusDescription: JSON.stringify(err, null, 2) });
      });
  }, [blockNumber]);

  const transactionStatus = useMemo(() => {
    return { status: data?.status, hash: data?.hash, requestId: data?.requestId, statusDescription: data?.statusDescription } as TransactionStatus;
  }, [data]);

  return [transactionStatus, mintQuantumon];
}