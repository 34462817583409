import { useCallback } from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import connectors from "../connectors/Connector";
import { AbstractConnector } from "@web3-react/abstract-connector";

export function useWalletConnectCallback() {
  const { activate } = useActiveWeb3React();

  const createConnectHandler = useCallback(
    async (connector: AbstractConnector) => {
      try {
        // const connector = connectors.injected;
        // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
        await activate(connector);
        localStorage.connected = "yes";
      } catch (error) {
        console.error("createConnectHandler", error);
      }
    },
    [activate]
  );

  const connectWallet = useCallback(
    (connectorType: string) => {
      let connector;
      if (connectorType === "injected") {
        connector = connectors.injected;
      }  else {
        connector = connectors.injected;
      }

      localStorage.connectorType = connectorType;
      console.log(connector)
      createConnectHandler(connectors.injected);
    },
    [createConnectHandler]
  );

  return [connectWallet];
}