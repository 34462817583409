const testing = false;
export const currentConnection = testing ? "testnet" : "mainnet";

export const QUANTUMON_ADDRESS: { [index: number]: string } = {
  137: "0x19b77817d2ceA3f9953D556385C469902Cf8aD15",
};

export const AIRNODERRP_ADDRESS: { [index: number]: string } = {
  137: "0x19b77817d2ceA3f9953D556385C469902Cf8aD15",
};

export const etheriumNetwork = "ethereum";
export const bscNetwork = "bsc";
export const maticNetwork = "matic";
export const harmonyNetwork = "harmony";

export const etherConfig = {
  network_id: {
    mainet: "1",
    koven: "42",
  },
};

export const NETWORK_TYPE = 1; // testing:1, mainnet:0

export const supportedChains = [
  1, 4, 1285, 1287, 97, 56, 137, 80001, 1666700000, 1666600000,
];

export const bscConfig = {
  network_id: {
    mainnet: "56",
    testnet: "97",
  },
  network_rpc_mainnet: "https://bsc-dataseed.binance.org/",
  network_rpc_testnet: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

export const maticConfig = {
  network_id: {
    mainnet: "137",
    testnet: "80001",
  },
  network_rpc_testnet: "https://mumbai-explorer.matic.today",
};

export const harmonyConfig = {
  chainId: {
    mainnet: "1666600000",
    testnet: "1666700000",
  },
};

export const supportedNetworks = ["137", "80001"];

export const ankrRpc = {
  137: "https://rpc.ankr.com/polygon",
};

export const NetworkContextName = "NETWORK";
