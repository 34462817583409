import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { getContract } from "../utils/contractUtils";
import useActiveWeb3React from "./useActiveWeb3React";
import QUANTUMON_ABI from "../contracts/abi/Quantumon.json"
import AIRNODERRP_ABI from "../contracts/abi/AinodeRrpV0.json"
import { AIRNODERRP_ADDRESS, QUANTUMON_ADDRESS } from "../constants/index";

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}


export function useQuantumonContract(
  withSignerIfPossible?: boolean
): Contract | null {
  const { chainId } = useActiveWeb3React();

  return useContract(
    QUANTUMON_ADDRESS[chainId ? chainId : 1],
    QUANTUMON_ABI,
    withSignerIfPossible
  );
}

export function useAirnodeRrp(
  withSignerIfPossible?: boolean
): Contract | null {
  const { chainId } =useActiveWeb3React();

  return useContract(
    AIRNODERRP_ADDRESS[chainId ? chainId : 1],
    AIRNODERRP_ABI,
    withSignerIfPossible
  )
}