import { createWeb3ReactRoot, Web3ReactProvider } from "web3-react-core";
import Home from './components/home/Home';
import Header from './components/home/Header';
import Footer from './components/home/Footer';
import getLibrary from "./utils/getLibrary";
import { NetworkContextName } from "./constants";
import { BlockUpdater } from "hooks/useBlockNumber";
import { Fragment } from "react";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function App() {

  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <BlockUpdater></BlockUpdater>
          <Fragment>
            <Header></Header>
            <Home></Home>
            <Footer></Footer>
          </Fragment>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </div >
  );
}

export default App;
