import React from 'react'

export default function Footer() {
    return (
        <footer className="site-footer">


            <section className="footer-block2">
                <div className="inner-column">


                    <div className="flex-text">
                        <p className="dev-name">Developed by Lalive, beejsbj</p>
                        <p>Copyright ©2022 Quantomon. All rights reserved.</p>
                    </div>

                    <div className="flex-child">
                        <a target="_blank" rel="noopener noreferrer" href="https://api3.org/qrng">
                            <img src="https://api3.org/img/quantum-random-numbers/embed-badges/Type=Black, Size=Small.png" width="120px" height="58px" />
                        </a>
                    </div>

                </div>
            </section>

        </footer>
    )
}
